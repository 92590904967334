<template>
  <div class="user-info">
    <!-- <el-breadcrumb separator="/" style="margin-bottom: .15rem;">
      <el-breadcrumb-item :to="{ path: '/user/userList' }">用户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user/userList' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>用户详情</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-tabs v-model="fullPath" @tab-click="onLink">
      <el-tab-pane v-for="item in userMenuObj" :key="item.path" :label="item.title" :name="item.path">
      </el-tab-pane>
    </el-tabs>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { userMenuObj } from "@/db/objs"
export default {
  data() {
    return {
      userMenuObj,
      fullPath: this.$route.path
    };
  },
  methods: {
    onLink() {
      if (this.$route.path != this.fullPath) {
        this.$router.push({
          path: this.fullPath,
          query: this.$route.query
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-info {
  padding: .15rem;
}
</style>